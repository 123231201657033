<template>
  <ul v-if="items" role="menu">
    <template v-for="(item, i) of items">
      <li
        v-if="visible(item) && !item.separator"
        :key="item.label || i"
        :class="[
          {
            'layout-root-menuitem': root,
            'active-menuitem': activeIndex === i && !item.disabled,
            'active-menuitem-notrouter': activeIndex === i && !item.items,
          },
        ]"
        role="none"
      >
        <div class="arrow" v-if="item.items && root === true"></div>
        <router-link
          v-if="item.to"
          :to="item.to"
          :style="item.style"
          :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
          :target="item.target"
          exact
          @click="onMenuItemClick($event, item, i)"
          @mouseenter="onMenuItemMouseEnter(i)"
          role="menuitem"
          v-ripple
        >
          <i :class="['layout-menuitem-icon', item.icon]"></i>
          <span class="layout-menuitem-text">{{ t("nav." + item.label) }}</span>
          <i
            v-if="item.items"
            class="pi pi-fw pi-angle-down menuitem-toggle-icon"
          ></i>
          <span v-if="item.badge" class="menuitem-badge">{{ item.badge }}</span>
        </router-link>
        <a
          v-if="!item.to"
          :href="item.url || '#'"
          :style="item.style"
          :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
          :target="item.target"
          @mouseenter="onMenuItemMouseEnter(i)"
          @click="onMenuItemClick($event, item, i)"
          role="menuitem"
          v-ripple
        >
          <i :class="['layout-menuitem-icon', item.icon]"></i>
          <span class="layout-menuitem-text">{{ t("nav." + item.label) }}</span>
          <i
            v-if="item.items"
            class="pi pi-fw pi-angle-down menuitem-toggle-icon"
          ></i>
          <span v-if="item.badge" class="menuitem-badge">{{ item.badge }}</span>
        </a>
        <div v-if="root" class="layout-menu-tooltip">
          <div class="layout-menu-tooltip-arrow"></div>
          <div class="layout-menu-tooltip-text">
            {{ t("nav." + item.label) }}
          </div>
        </div>
        <div
          v-if="root"
          class="layout-menuitem-root-text"
          style="text-transform: uppercase"
        >
          {{ t("nav." + item.label) }}
        </div>
        <transition name="layout-submenu-container">
          <appsubmenu
            v-show="
              item.items &&
              (root &&
              (!isHorizontal() ||
                (isHorizontal() && (mobileMenuActive || activeIndex !== null)))
                ? true
                : activeIndex === i)
            "
            :items="visible(item) && item.items"
            @menuitem-click="$emit('menuitem-click', $event)"
            :layoutMode="layoutMode"
            :menuActive="menuActive"
            :parentMenuItemActive="activeIndex === i"
          ></appsubmenu>
        </transition>
      </li>
      <li
        class="p-menu-separator"
        :style="item.style"
        v-if="visible(item) && item.separator"
        :key="'separator' + i"
        role="separator"
      ></li>
    </template>
  </ul>
</template>
<script>
import { onMounted, ref } from "vue";
import EventBus from "./event-bus";
import { useI18n } from "vue-i18n";

export default {
  name: "appsubmenu",
  emits: ["menuitem-click", "root-menuitem-click"],
  props: {
    items: Array,
    layoutMode: String,
    menuActive: Boolean,
    mobileMenuActive: Boolean,
    root: {
      type: Boolean,
      default: false,
    },
    parentMenuItemActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const activeIndex = ref(null);

    onMounted(() => {
      EventBus.on("reset-active-index", () => {
        if (props.layoutMode === "horizontal" || props.layoutMode === "slim") {
          activeIndex.value = null;
        }
      });
    });

    const onMenuItemClick = (event, item, index) => {
      if (item.disabled) {
        event.preventDefault();
        return;
      }

      if (item.command) {
        item.command({ originalEvent: event, item: item });
        event.preventDefault();
      }

      if (item.items) {
        event.preventDefault();
      }

      if (props.root) {
        emit("root-menuitem-click", {
          originalEvent: event,
        });
      }

      if (item.items) {
        activeIndex.value = index === activeIndex.value ? null : index;
      } else {
        if (props.layoutMode !== "static") {
          const ink = getInk(event.currentTarget);
          if (ink) {
            removeClass(ink, "p-ink-active");
          }
        }
      }

      emit("menuitem-click", {
        originalEvent: event,
        item: item,
      });
    };

    const isMobile = () => {
      return window.innerWidth <= 1025;
    };

    const isSlim = () => {
      return props.layoutMode === "slim";
    };

    const isHorizontal = () => {
      return props.layoutMode === "horizontal";
    };

    const onMenuItemMouseEnter = (index) => {
      if (
        props.root &&
        props.menuActive &&
        (props.layoutMode === "horizontal" || props.layoutMode === "slim") &&
        !isMobile()
      ) {
        activeIndex.value = index;
      }
    };

    const visible = (item) => {
      return typeof item.visible === "function"
        ? item.visible()
        : item.visible !== false;
    };

    const getInk = (el) => {
      for (let i = 0; i < el.children.length; i++) {
        if (
          typeof el.children[i].className === "string" &&
          el.children[i].className.indexOf("p-ink") !== -1
        ) {
          return el.children[i];
        }
      }
    };

    const removeClass = (element, className) => {
      if (element.classList) {
        element.classList.remove(className);
      } else {
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
      }
    };

    const { t } = useI18n();

    return {
      activeIndex,
      mobileMenuActive: props.mobileMenuActive,
      onMenuItemClick,
      isSlim,
      isHorizontal,
      onMenuItemMouseEnter,
      visible,
      t,
    };
  },
};
</script>

<style scoped></style>
