import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "@/firebase";

const getDefaultState = () => {
  return {
    graveyard: {},
    graveyards: [],
    status: {},
    waiting: false,
  };
};

const state = getDefaultState();

const actions = {
  async retrieveGraveyards({ commit }) {
    commit("wait");
    try {
      const q = query(
        collection(db, "graveyards"),
        orderBy("city"),
        orderBy("name")
      );
      const querySnapshot = await getDocs(q);
      const graveyards = querySnapshot.docs.map((doc) => {
        const graveyard = doc.data();
        graveyard.id = doc.id;
        return graveyard;
      });
      commit("setGraveyards", graveyards);
    } catch (error) {
      commit("failure", error);
    }
  },

  async retrieveGraveyard({ commit }, id) {
    commit("wait");
    try {
      const docRef = doc(db, "graveyards", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const graveyard = docSnap.data();
        graveyard.id = docSnap.id;
        commit("setGraveyard", graveyard);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status = {
      error: true,
      msg: error,
    };
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setGraveyard(state, graveyard) {
    state.graveyard = graveyard;
    state.waiting = false;
  },
  setGraveyards(state, graveyards) {
    state.graveyards = graveyards;
    state.waiting = false;
  },
  success(state, msg) {
    if (msg) {
      state.status = {
        success: true,
        msg: msg,
      };
    }
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getGraveyard(state) {
    return state.graveyard;
  },
  getGraveyards(state) {
    return state.graveyards;
  },
};

const graveyard = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default graveyard;
