import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../pages/Home.vue"),
    meta: { breadcrumb: [] },
  },
  {
    path: "/graveyards/",
    name: "graveyards",
    component: () => import("../pages/Graveyards.vue"),
    meta: { breadcrumb: ["graveyards"] },
  },
  {
    path: "/graveyards/:graveyardId",
    name: "graveyard",
    component: () => import("../pages/Graveyard.vue"),
    meta: { breadcrumb: ["graveyards", "graveyard"] },
  },
  {
    path: "/grave/:graveId",
    name: "grave",
    component: () => import("../pages/Grave.vue"),
    meta: { breadcrumb: ["graveyards", "graveyard", "grave"] },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../pages/About.vue"),
    meta: { breadcrumb: ["about"] },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export default router;
