import { createI18n } from "vue-i18n";

function loadLocaleTexts() {
  const locales = require.context(
    "./languages",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const texts = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      texts[locale] = locales(key);
    }
  });
  return texts;
}

const i18n = createI18n({
  legacy: false,
  locale:
    localStorage.getItem("app-locale") ||
    navigator.language.substring(0, 2) ||
    "fi",
  fallbackLocale: process.env.I18N_FALLBACK_LOCALE || "fi",
  messages: loadLocaleTexts(),
});

export default i18n;
