const languageList = [
  {
    name: "English",
    value: "en",
  },
  {
    name: "Suomi",
    value: "fi",
  },
];

export default languageList;
