<template>
  <div class="topbar clearfix">
    <div class="topbar-left p-2">
      <router-link to="/">
        <!--<button class="p-link">
          <img
            alt="Some Logo"
            src="/layout/images/logo.png"
            class="topbar-logo"
          />
        </button>-->
        <p class="text-white font-bold text-xl">
          {{ t("headers.main") }}
        </p>
      </router-link>
    </div>

    <div class="topbar-right">
      <Button class="p-link" id="menu-button" @click="onMenuButtonClick">
        <i class="pi pi-bars"></i>
      </Button>

      <button
        class="p-link"
        id="topbar-menu-button"
        @click="onTopbarMenuButtonClick"
      >
        <i class="pi pi-globe"></i>
      </button>
      <ul :class="topbarItemsClass">
        <!--<li
          v-if="profileMode === 'top' || horizontal"
          :class="[
            'profile-item',
            { 'active-top-menu': activeTopbarItem === 'profile' },
          ]"
          @click="
            $emit('topbar-item-click', {
              originalEvent: $event,
              item: 'profile',
            })
          "
        >
          <button class="p-link">
            <img
              alt="User"
              class="profile-image"
              src="/layout/images/avatar.png"
            />
            <div>
              <span class="topbar-item-name">Isabel Lopez</span>
              <span class="topbar-item-role">Marketing</span>
            </div>
          </button>

          <transition name="layout-submenu-container">
            <ul
              class="layout-menu fadeInDown"
              v-show="activeTopbarItem === 'profile'"
            >
              <li v-for="item in menuItems" :key="item.label" role="menuitem">
                <button class="p-link topbar-item-button" @click="item.click">
                  <i :class="item.icon"></i>
                  <span>{{ t("nav." + item.label) }}</span>
                </button>
              </li>
            </ul>
          </transition>
        </li>-->

        <li
          :class="[{ 'active-top-menu': activeTopbarItem === 'Language' }]"
          @click="
            $emit('topbar-item-click', {
              originalEvent: $event,
              item: 'language',
            })
          "
        >
          <button class="p-link">
            <i class="topbar-icon pi pi-fw pi-globe"></i>
            <span class="topbar-item-name">{{ t("nav.language") }}</span>
          </button>
          <transition name="layout-submenu-container">
            <ul
              class="layout-menu fadeInDown"
              v-show="activeTopbarItem === 'language'"
            >
              <li
                v-for="(language, index) in languages"
                :key="index"
                role="menuitem"
              >
                <a href="javascript:;" @click="setLanguage(language.value)">
                  <span>{{ language.name }}</span>
                </a>
              </li>
            </ul>
          </transition>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import languageList from "./i18n/list";

export default {
  props: {
    topbarMenuActive: Boolean,
    profileMode: String,
    horizontal: Boolean,
    activeTopbarItem: String,
  },
  setup(props, { emit }) {
    const onMenuButtonClick = (event) => {
      emit("menubutton-click", event);
    };

    const onTopbarMenuButtonClick = (event) => {
      emit("topbar-menubutton-click", event);
    };

    const topbarItemsClass = computed(() => {
      return [
        "topbar-items fadeInDown",
        {
          "topbar-items-visible": props.topbarMenuActive,
        },
      ];
    });

    const router = useRouter();

    const navigateTo = (route) => {
      router.push(route);
    };

    const store = useStore();

    const menuItems = [
      {
        label: "profile",
        click: () => navigateTo("/profile"),
        icon: "pi pi-fw pi-user",
      },
      {
        label: "settings",
        click: () => navigateTo("/settings"),
        icon: "pi pi-fw pi-cog",
      },
      {
        label: "logout",
        click: () => store.dispatch("user/signOut"),
        icon: "pi pi-fw pi-sign-out",
      },
    ];

    const { t, locale } = useI18n();

    const languages = languageList;

    const setLanguage = (lang) => {
      localStorage.setItem("app-locale", lang);
      locale.value = lang;
    };

    return {
      onMenuButtonClick,
      onTopbarMenuButtonClick,
      topbarItemsClass,
      menuItems,
      languages,
      setLanguage,
      t,
    };
  },
};
</script>
