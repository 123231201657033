<template>
  <div>
    <div :class="['profile', { 'profile-expanded': expanded }]">
      <button class="p-link" @click="onClick">
        <img
          alt="Profile"
          class="profile-image"
          src="layout/images/avatar.png"
        />
        <div>
          <span class="profile-name">Isabel Oliviera</span>
          <i class="pi pi-fw pi-chevron-down"></i>
          <span class="profile-role">Marketing</span>
        </div>
      </button>
    </div>

    <transition name="layout-profile-menu">
      <ul class="layout-menu profile-menu" v-show="expanded">
        <li v-for="item in menuItems" :key="item.label" role="menuitem">
          <a href="#" :tabIndex="expanded ? null : '-1'" @click="item.click">
            <i :class="item.icon"></i>
            <span>{{ t("nav." + item.label) }}</span>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  emits: ["profile-click"],
  props: {
    expanded: {
      type: Boolean,
      default: null,
    },
  },
  setup(_, { emit }) {
    const onClick = (event) => {
      emit("profile-click", event);
      event.preventDefault();
    };

    const router = useRouter();

    const navigateTo = (route) => {
      router.push(route);
    };

    const store = useStore();

    const menuItems = [
      {
        label: "profile",
        click: () => navigateTo("/profile"),
        icon: "pi pi-fw pi-user",
      },
      {
        label: "settings",
        click: () => navigateTo("/settings"),
        icon: "pi pi-fw pi-cog",
      },
      {
        label: "logout",
        click: () => store.dispatch("user/signOut"),
        icon: "pi pi-fw pi-sign-out",
      },
    ];

    const { t } = useI18n();

    return {
      onClick,
      menuItems,
      t,
    };
  },
  name: "AppInlineProfile",
};
</script>

<style scoped></style>
